import { ContactLabel } from "./label-list";

export class ContactViewModel {
  id: number;
  firmId?: number;
  firstName: string;
  firmLabelId?: number;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: any;
  contactLabel: ContactLabel;
  contactStatusId: number;
  contactStatus?: string;
  titleId?: number;
  contactSource?: string;
  website?: string;
  contact?: string;
}

// public Id: number;
// public TitleId: number;
// public FirstName: string;
// public LastName: string;
// public JobDescription: string;
// public CompanyName: string;
// public PhoneNumber: string;
// public Email: string;
// public Website: string;
// public InstantMessenger: string;
// public DateAdded: Date;
// public IsDeleted: boolean;
// public Status: string;
// public ContactSourceId: number;
// public ContactSource: ContactSource;
// public Title: Title;
// public ContactLabel: ContactLabel;
// public ClientInvoice: ICollection<ClientInvoice>;
// public MatterClient: ICollection<MatterClient>;
// public MatterNotificationConfiguration: ICollection<MatterNotificationConfiguration>;
// public Payment: ICollection<Payment>;

export const ContactListSample: ContactViewModel[] = [
  {
    id: 9,
    firstName: "Erubu",
    lastName: "Eyitayo",
    companyName: "RCC Contructions LTD",
    email: "erubs@gmail.com",
    phoneNumber: 2348071913472,
    contactStatusId: 1,
    contactLabel: {
      id: 2,
      caption: "close",
      colorId: "tag_red"
    }
  },
  {
    id: 10,
    firstName: "Onyedikachukwu",
    lastName: "Ogile",
    companyName: "Afriville Technologies",
    email: "dika21@gmail.com",
    phoneNumber: 2348072119669,
    contactStatusId: 2,
    contactLabel: {
      id: 1,
      caption: "international",
      colorId: "tag_yellow"
    }
  },
  {
    id: 7,
    firstName: "Andrew ",
    lastName: "Jackson",
    companyName: "GIT Limited",
    email: "andrew_j@yahoo.com",
    phoneNumber: 2348022908498,
    contactStatusId: 3,
    contactLabel: {
      id: 2,
      caption: "lead",
      colorId: "tag_green"
    }
  },
  {
    id: 6,
    firstName: "Babatunde ",
    lastName: "Calebs",
    companyName: "Thribyte Technologies",
    email: "calebs@qa.team",
    phoneNumber: 2348067237890,
    contactStatusId: 3,
    contactLabel: {
      id: 1,
      caption: "international",
      colorId: "tag_yellow"
    }
  }
];
