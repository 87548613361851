import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { AddDocumentFolderModel } from "../models/view-model/document-folder";

@Injectable({
  providedIn: "root"
})
export class AddDocumentFolderService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  public addDocumentFolder(
    addFolder: AddDocumentFolderModel
  ): Observable<any> {
    const url = this.config.getConfig().serverEndpoint + "file/Directory";
    console.log(url);
    return this.httpClient.post<any>(url, addFolder);
  }
}
