import { UserLite } from "./user-lite";

export class GroupDetail {
  id?: number;
  name?: string;
  description?: string;
  groupUsers?: UserLite[];
}

export interface GroupDetail {
  id?: number;
  name?: string;
  description?: string;
  users?: UserLite[];
}
