import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AppConfigService } from "../../views/shared/helpers/app-config.service";
import { Endpoints } from "../models/shared-model/endpoint";
import { CaseLiteViewModel } from "../models/view-model/cases-list";

@Injectable({
  providedIn: "root"
})
export class DocketService {
  Endpoints = new Endpoints(this.config);

  constructor(
    private httpClient: HttpClient,
    private config: AppConfigService
  ) {
  }

  public SearchDocketCaseList(searchQuery): Observable<CaseLiteViewModel[]> {
    const url = this.config.getConfig().serverEndpoint + `matter/Casebynameorsuitnumber?SearchQuery=${searchQuery}`;
    return this.httpClient.get<CaseLiteViewModel[]>(url);
  }

  public loadDocketCaseList(search = "", date: string ): Observable<CaseLiteViewModel[]> {
    let url = this.config.getConfig().serverEndpoint +`matter/Casebynameorsuitnumber/${date}`;
     console.log (url);
    if (search) {
      url += "?SearchQuery=" + search;
    }
    return this.httpClient
      .get<CaseLiteViewModel[]>(url)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError("Something bad happened; please try again later.");
  }
}
