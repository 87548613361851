import { FileUploadModel } from "../generic-model.ts/file-upload";
import { FileViewModel } from "../view-model/fileViewModel";

export class EditFirmDetailsModel {
  id: number;
  name: string;
  email?: string;
  website?: string;
  phoneNumber?: string;
  address?: string;
  countryId?: number;
  stateId?: number;
  state?: string;
  country?: string;
  creator?: number;
  fileModel: FileUploadModel;
  contactEmail?: string;

  logo: FileViewModel;
}
